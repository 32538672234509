import React from "react";

const Cookies = () => {
  return (
    <main>
      <section className="sugar-privacy__section pad">
        <h1>Welkom op onze website!</h1>
        <p className="t-15">
          Om u de beste gebruikerservaring te bieden en het browsen op de site
          zo soepel mogelijk te laten verlopen, gebruiken we cookies. In dit
          beleid leggen we uit wat cookies zijn, hoe we ze gebruiken en welke
          keuzes u hebt met betrekking tot ons gebruik ervan.
        </p>
        <h2>Wat zijn cookies?</h2>
        <p className="t-15">
          Cookies zijn kleine tekstbestanden die op uw apparaat (computer,
          smartphone, tablet) worden opgeslagen wanneer u een website bezoekt.
          Ze helpen websites uw acties en voorkeuren (zoals taal, lettergrootte,
          gebruikersnaam) te onthouden, zodat u ze niet telkens opnieuw hoeft in
          te voeren wanneer u een site bezoekt of van de ene pagina naar de
          andere gaat.
        </p>
        <h2>Hoe gebruiken wij cookies?</h2>
        <p className="t-20">
          Wij gebruiken cookies voor de volgende doeleinden:
        </p>
        <ul className="sugar-privacy__list">
          <li className="t-15">
            * Sitefunctionaliteit garanderen: Cookies helpen de site goed te
            functioneren, bijvoorbeeld door u in staat te stellen in te loggen
            op uw account en uw winkelwagen te gebruiken.
          </li>
          <li className="t-15">
            * Verbeterde gebruikerservaring: We gebruiken cookies om uw
            voorkeuren (zoals taal of regio) te onthouden om uw site-ervaring
            persoonlijker en gemakkelijker te maken.
          </li>
          <li className="t-15">
            * Siteverkeersanalyse: We gebruiken cookies om anonieme informatie
            te verzamelen over hoe gebruikers onze site gebruiken. Deze gegevens
            helpen ons de site te verbeteren en relevanter voor u te maken.
          </li>
          <li className="t-15">
            * Adverteren en marketing: We kunnen cookies gebruiken om u
            advertenties te tonen waarvan wij denken dat ze interessant voor u
            kunnen zijn. We gebruiken ook cookies om de effectiviteit van onze
            advertentiecampagnes bij te houden.
          </li>
        </ul>
        <h2>Welke soorten cookies gebruiken wij?</h2>
        <p className="t-15">Wij gebruiken verschillende soorten cookies:</p>
        <ul className="privacy__list">
          <li className="t-15">
            * Noodzakelijke cookies: Deze cookies zijn essentieel om de website
            goed te laten functioneren. Ze verzamelen geen informatie over u.
          </li>
          <li className="t-15">
            * Functionele cookies: Deze cookies stellen de website in staat om
            uw voorkeuren (zoals taal of regio) te onthouden om uw ervaring op
            de website persoonlijker en gemakkelijker te maken.
          </li>
          <li className="t-15">
            * Analytische cookies: Deze cookies verzamelen anonieme informatie
            over hoe gebruikers onze website gebruiken. Deze gegevens helpen ons
            om de website te verbeteren en relevanter voor u te maken.
          </li>
          <li className="t-15">
            * Marketingcookies: Deze cookies worden gebruikt om u advertenties
            te tonen waarvan wij denken dat ze interessant voor u kunnen zijn.
          </li>
        </ul>
        <h2>Hoe kan ik cookies beheren?</h2>
        <p className="t-20">
          U kunt de cookies die op onze site worden gebruikt, beheren.
        </p>
        <ul className="privacy__list">
          <li className="t-15">
            *U kunt uw browserinstellingen op elk gewenst moment wijzigen om
            cookies te blokkeren.
          </li>
          <li className="t-15">
            *U kunt bestaande cookies van uw apparaat verwijderen.
          </li>
          <li className="t-15">
            *U kunt de beste advertentiecampagnes kiezen die u interesseren.
          </li>
        </ul>
        <h2>Aanvullende informatie</h2>
        <p className="t-15">Meer informatie over cookies vindt u hier.</p>
        <h2>Neem contact met ons op</h2>
        <p className="t-15">
          Als u vragen hebt over ons cookiebeleid, kunt u contact met ons
          opnemen via sugarrush-spielautomat@gmail.com.
          <br />
          Belangrijk: als u ervoor kiest om cookies te blokkeren, werken sommige
          functies van onze site mogelijk niet goed.
        </p>
        <h2>Bedankt voor het gebruiken van onze site!</h2>
      </section>
    </main>
  );
};

export default Cookies;
