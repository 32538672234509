import React, { useEffect, useState } from "react";
import "./Popup.css";

const Popup = () => {
  const [isPopupAccepted, setIsPopupAccepted] = useState(false);

  useEffect(() => {
    const popup = localStorage.getItem("popup");
    if (popup) {
      setIsPopupAccepted(true);
    }
  }, []);

  function addPopupCookie() {
    localStorage.setItem("popup", "1");
    setIsPopupAccepted(true);
  }

  return (
    !isPopupAccepted && (
      <div className="sugar-popup-cookie">
        <form className="sugar-popup__cookie-form">
          <p className="sugar-popup__cookie-header">Cookies en Privacy</p>
          <p className="sugar-popup__cookie-text">
            Wij gebruiken cookies om de functionaliteit van onze website te
            verbeteren en u gepersonaliseerde inhoud te bieden. Door deze site
            te blijven gebruiken, gaat u akkoord met ons gebruik van cookies en
            ons beleid inzake gegevensverwerking.
          </p>
          <div className="sugar-popup__btn-container">
            <a href="/cookies" className="sugar-popup__btn-cookie">
              Aanvullende informatie
            </a>
            <p
              className="sugar-popup__btn-cookie sugar-popup__btn-agree"
              onClick={addPopupCookie}
            >
              Verkregen
            </p>
          </div>
        </form>
      </div>
    )
  );
};

export default Popup;
