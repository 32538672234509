import React, { useState } from "react";
import "../Book/GamePage.css";
import phoenixCover from "../../images/phoenix-up-cash-cover.webp";
import phoenixCoverMob from "../../images/phoenix-up-cash-cover-mob.webp";
import closeBtn from "../../images/close-btn.svg";
import ellips from "../../images/elips-mob.svg";

const Phoenix = () => {
  const [isGameVisible, setIsGameVisible] = useState(false);

  return (
    <>
      <section className="sugar-game-page pad">
        <h1 className="sugar-main__header col-white">Phoenix Up Cash</h1>
        <p className="sugar-main__text col-white">
          Ga op een mythologische reis in een spel van de tandem van
          ontwikkelaars Relax Gaming en Trigger Button. Met moderne graphics en
          een genereus bonussysteem krijgen spelers de kans om een leuke
          speelsessie te hebben. De hoofdpersoon van het spel is een feniksvogel
          die je vergezelt op je weg naar de schat. Het spel heeft slechts 4
          winlijnen, je denkt misschien dat dit niet genoeg is, maar het spel
          biedt een inzetvermenigvuldiger van 10000 keer.
        </p>
      </section>
      <section className="sugar-game-page__cover">
        <img
          src={phoenixCover}
          alt="Phoenix Up Cash cover"
          title="Phoenix Up Cash cover"
          className="sugar-game-page__img pc"
        />
        <img
          src={phoenixCoverMob}
          alt="Phoenix Up Cash cover"
          title="Phoenix Up Cash cover"
          className="sugar-game-page__img mob"
        />
        <div className="sugar-game-page__link">
          <p
            className="sugar-game-page__text"
            onClick={() => setIsGameVisible(true)}
          >
            Speel demo
          </p>
          <img src={ellips} alt="ellips" title="ellips" className="mob sugar-game-page__ellips" />
        </div>
      </section>
      {isGameVisible && (
        <div className="sugar-game-popup">
          <div className="sugar_popup__game">
            <img
              src={closeBtn}
              alt="close popup btn"
              title="close popup btn"
              className="sugar-popup__close"
              onClick={() => setIsGameVisible(false)}
            />
            <iframe
              title="Phoenix Up Cash"
              className="sugar-game__frame"
              src="https://d2drhksbtcqozo.cloudfront.net/casino/apex/layer/?channel=mobile&amp;gameid=phoenixupcash&amp;moneymode=fun&amp;jurisdiction=MT&amp;partnerid=1&amp;&amp;apex=1&amp;gameurl=https%3A%2F%2Fd2drhksbtcqozo.cloudfront.net%2Fcasino%2Flauncher.html"
            ></iframe>
          </div>
        </div>
      )}
    </>
  );
};

export default Phoenix;
