import React from "react";

const Privacy = () => {
  return (
    <main>
      <section className="sugar-privacy__section pad">
        <h1>Privacybeleid van sugarrush-spielautomat.com</h1>
        <p className="t-15">
          Bij sugarrush-spielautomat.com, toegankelijk via
          sugarrush-spielautomat.com, is een van onze belangrijkste prioriteiten
          de privacy van onze bezoekers. Dit Privacybeleid document bevat
          soorten informatie die worden verzameld en vastgelegd door
          sugarrush-spielautomat.com en hoe we deze gebruiken. <br />
          Als u aanvullende vragen hebt of meer informatie nodig hebt over ons
          Privacybeleid, aarzel dan niet om contact met ons op te nemen.
        </p>
        <h2>Logbestanden</h2>
        <p className="t-15">
          sugarrush-spielautomat.com volgt een standaardprocedure voor het
          gebruik van logbestanden. Deze bestanden registreren bezoekers wanneer
          ze websites bezoeken. Alle hostingbedrijven doen dit, evenals enkele
          analyses van de hostingdiensten. De informatie die door logbestanden
          wordt verzameld, omvat internetprotocol (IP)-adressen, browsertype,
          internetprovider (ISP), datum- en tijdstempel,
          verwijzende/afsluitpagina's en mogelijk het aantal klikken. Deze zijn
          niet gekoppeld aan informatie die persoonlijk identificeerbaar is. Het
          doel van de informatie is om trends te analyseren, de site te beheren,
          de bewegingen van gebruikers op de website te volgen en demografische
          informatie te verzamelen. Ons privacybeleid is gemaakt met behulp van
          de Privacy Policy Generator.
        </p>
        <h2>Onze advertentiepartners</h2>
        <p className="t-15">
          Some advertisers on our site may use cookies and web beacons. Our
          advertising partners are listed below. Each of our advertising
          partners has its own privacy policy for its policies regarding user
          data. For easier access, we have hyperlinked to their privacy policies
          below.
        </p>
        <h2>Privacybeleid</h2>
        <p className="t-15">
          U kunt deze lijst raadplegen om het privacybeleid te vinden voor elk
          van de advertentiepartners van sugarrush-spielautomat.com.
          <br />
          Advertentieservers of advertentienetwerken van derden gebruiken
          technologieën zoals cookies, JavaScript of webbakens die worden
          gebruikt in hun respectieve advertenties en links die op
          sugarrush-spielautomat.com verschijnen en die rechtstreeks naar de
          browser van gebruikers worden verzonden. Ze ontvangen automatisch uw
          IP-adres wanneer dit gebeurt. Deze technologieën worden gebruikt om de
          effectiviteit van hun advertentiecampagnes te meten en/of om de
          advertentie-inhoud die u ziet op websites die u bezoekt, te
          personaliseren. <br />
          Houd er rekening mee dat sugarrush-spielautomat.com geen toegang heeft
          tot of controle heeft over deze cookies die worden gebruikt door
          externe adverteerders.
        </p>
        <h2>Privacybeleid van derden</h2>
        <p className="t-15">
          Het privacybeleid van sugarrush-spielautomat.com is niet van
          toepassing op andere adverteerders of websites. Daarom adviseren wij u
          om het privacybeleid van deze externe advertentieservers te raadplegen
          voor meer gedetailleerde informatie. Het kan hun praktijken en
          instructies bevatten over hoe u zich kunt afmelden voor bepaalde
          opties.
          <br />U kunt ervoor kiezen om cookies uit te schakelen via uw
          individuele browseropties. Voor meer gedetailleerde informatie over
          cookiebeheer met specifieke webbrowsers, kunt u deze vinden op de
          respectieve websites van de browsers. Wat zijn cookies?
        </p>
        <h2>Informatie voor kinderen</h2>
        <p className="t-15">
          Een ander onderdeel van onze prioriteit is het toevoegen van
          bescherming voor kinderen wanneer ze het internet gebruiken. We
          moedigen ouders en verzorgers aan om hun online activiteiten te
          observeren, eraan deel te nemen en/of te controleren en te begeleiden.
          <br />
          sugarrush-spielautomat.com verzamelt niet bewust persoonlijke
          identificeerbare informatie van kinderen jonger dan 13 jaar. Als u
          denkt dat uw kind dit soort informatie op onze website heeft
          verstrekt, raden we u ten zeerste aan om onmiddellijk contact met ons
          op te nemen en we zullen ons best doen om dergelijke informatie
          onmiddellijk uit onze bestanden te verwijderen.
        </p>
        <h2>Alleen online privacybeleid</h2>
        <p className="t-15">
          Dit privacybeleid is alleen van toepassing op onze online activiteiten
          en is geldig voor bezoekers van onze website met betrekking tot de
          informatie die zij hebben gedeeld en/of verzameld op
          sugarrush-spielautomat.com. Dit beleid is niet van toepassing op
          informatie die offline of via andere kanalen dan deze website is
          verzameld.
        </p>
        <h2>Toestemming</h2>
        <p className="t-15">
          Door onze website te gebruiken, stemt u in met ons privacybeleid en
          gaat u akkoord met de algemene voorwaarden.
        </p>
      </section>
    </main>
  );
};

export default Privacy;
