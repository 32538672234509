import React from "react";
import "./Footer.css";
import { Link } from "react-router-dom";
import age from "../../images/age.svg";

const Footer = () => {
  return (
    <footer className="sugar-footer pad">
      <div className="sugar-footer__top">
        <div className="sugar-footer__left">
          <p className="t-15 col-white">
            © Copyright 2019
            <br></br>sugarrush-spielautomat.com
          </p>
          <img src={age} title="age" alt="age" width="64" height="64"/>
        </div>
        <div className="sugar-footer__text">
          <p className="t-15 col-white">
            sugarrush-spielautomat.com - maakt gebruik van cookies om u maximaal
            gemak te garanderen. Als u op de site blijft, gaat u akkoord met ons
            gebruik van uw cookies.
            <Link to="/cookies" className="sugar-footer__link col-white">
              Om meer te leren
            </Link>
          </p>
          <p className="t-15 col-white">
            Ontkenner: De informatie op de site is alleen voor informatieve
            doeleinden. Alleen jij en je discipline zijn verantwoordelijk voor
            de regels van verantwoord gokken. Wees waakzaam en onthoud dat
            succes in het sociale online casino geen garantie is voor winst bij
            gokken voor echt geld.
          </p>
          <Link to="/privacy" className="sugar-footer__link col-white pc">
            Privacybeleid
          </Link>
        </div>
      </div>
      <Link to="/privacy" className="sugar-footer__link col-white mob">
        Privacybeleid
      </Link>
    </footer>
  );
};

export default Footer;
