import React from "react";
import "./Main.css";
import Casinos from "../Casinos/Casinos";
import history from "../../images/history.webp";
import plus from "../../images/plus.svg";
import minus from "../../images/minus.svg";

// import MainPopup from "../Popup/MainPopup";

const MainCover = () => {
  return (
    <main className="sugar-main">
      {/* <MainPopup/> */}
      <section className="sugar-main__cover">
        <h1 className="sugar-main__header col-white">
          De beste spelervaring bij een sociaal online casino
        </h1>
        <p className="sugar-main__text col-white">
          Ontdek een nieuwe spelervaring met sociale sites voor online casino's.
          Verken en vind antwoorden op al je vragen. Onze lezers krijgen de
          beste games aangeboden.
        </p>
      </section>
      <section className="sugar-about pad">
        <h2 className="hidden">About</h2>
        <p className="t-20">
          Vandaag gaan we dieper in op het sociale casino. We zullen
          interessante vragen beantwoorden en vergelijkingen maken, waarbij we
          de belangrijkste voor- en nadelen zullen identificeren. Aan de hand
          van een duidelijk voorbeeld kan elke lezer zelf een onafhankelijke
          beslissing nemen.
        </p>
        <p className="t-20">
          We hebben onschatbare ervaring in het werken met gelicentieerde en
          gecertificeerde aanbieders. Je kunt volledig gratis profiteren van
          onze kennis. Er is veel tijd en moeite geïnvesteerd in het voorbereide
          materiaal, we hopen dat u ervan zult genieten.
        </p>
      </section>
      <Casinos />
      <section className="sugar-history pad">
        <h2 className="sugar-history__header">
          geschiedenis van de opkomst van sociale casino ' s
        </h2>
        <p className="t-20">
          Analogen voor sociale casino's zagen we voor het eerst rond 2010 toen
          Facebook openbaar beschikbare spellen introduceerde. Gebruikers konden
          in groepen samenkomen en samen taken voltooien of samen strijden in
          hetzelfde spel. Na het voltooien van de resultaten konden spelers hun
          prestaties delen op sociale media. Het was in 2010 dat de sociale
          casino-industrie begon te groeien. Samen met de ontwikkeling van
          mobiele technologie verschenen er steeds meer applicaties die een
          gratis en veilige spelervaring boden die nu pas echt populair is
          geworden.
        </p>
        <img
          src={history}
          title="mensen tijdens de opleiding"
          alt="mensen tijdens de opleiding"
          width="1120"
          height="560"
          className="sugar-history__img"
        />
      </section>
      <section className="sugar-features pad">
        <h2>Kenmerken van sociale casino's</h2>
        <p className="t-20">
          Laten we eens een duidelijke blik werpen op de belangrijkste kenmerken
          van sociale casino's die spelers over de hele wereld aantrekken:
        </p>
        <ul className="sugar-list">
          <li className="sugar-item t-20">
            Spelveiligheid: Publiek beschikbare sites bieden enkel spellen aan
            van gelicentieerde aanbieders.
          </li>
          <li className="sugar-item t-20">
            Geen financiële risico's: Je hoeft geen echt geld te gebruiken om te
            beginnen met spelen.
          </li>
          <li className="sugar-item t-20">
            Toegankelijkheid: Sociale casino apps worden gemakkelijk ondersteund
            op telefoon, pc, tablet en zelfs webversie.
          </li>
          <li className="sugar-item t-20">
            Sociale interacties: Spelers kunnen zich aansluiten bij groepen en
            clans om gemeenschappelijke doelen te bereiken.
          </li>
          <li className="sugar-item t-20">
            Legaliteit: Ondanks strenge regelgeving in een aantal landen zijn
            sociale online casino's volledig legaal en geautoriseerd.
          </li>
          <li className="sugar-item t-20">
            Geschenken en bonussen: Regelmatige bezoeken en account activaties
            voorzien de gebruiker van royale beloningen die de progressie van je
            account versnellen.
          </li>
        </ul>
      </section>
      <section className="sugar-perks pad">
        <h2>Voor- en nadelen van sociale casino's:</h2>
        <div className="sugar-perks__container">
          <div className="sugar-perks__part">
            <img src={plus} alt="plus" title="plus" width="100" height="100" />
            <ul className="sugar-list">
              <li className="sugar-item t-20 col-grey">Gratis spellen</li>
              <li className="sugar-item t-20 col-grey">
                Breed scala aan spellen
              </li>
              <li className="sugar-item t-20 col-grey">
                Verschillende genres van spellen
              </li>
              <li className="sugar-item t-20 col-grey">
                24 uur per dag ondersteuning voor spelers
              </li>
              <li className="sugar-item t-20 col-grey">
                Ondersteuning voor moderne gadgets
              </li>
              <li className="sugar-item t-20 col-grey">Sociale interacties</li>
            </ul>
          </div>
          <div className="sugar-perks__part">
            <img
              src={minus}
              alt="minus"
              title="minus"
              width="100"
              height="100"
            />
            <ul className="sugar-list">
              <li className="sugar-item t-20 col-grey">Geen echte winsten</li>
              <li className="sugar-item t-20 col-grey">
                Kan intern saldo niet inruilen voor waardevolle prijzen
              </li>
              <li className="sugar-item t-20 col-grey">
                Jackpot emoties zijn niet levendig genoeg
              </li>
              <li className="sugar-item t-20 col-grey">
                De grafische kwaliteit hangt direct af van je apparaat
              </li>
            </ul>
          </div>
        </div>
      </section>
      <section className="sugar-types pad">
        <h2>Sociale online casino's vs klassieke casino's</h2>
        <div className="sugar-types__part">
          <div className="sugar-types___circle">Sociale</div>
          <ul className="sugar-list">
            <li className="sugar-item t-20 col-grey">
              Spelletjes voor de lol: Gratis spelletjes zonder echte inzetten.
            </li>
            <li className="sugar-item t-20 col-grey">
              Sociale interactie: Wedijveren met vrienden en successen delen.
            </li>
            <li className="sugar-item t-20 col-grey">
              Geen echte winsten: Er worden alleen virtuele valuta gebruikt
            </li>
            <li className="sugar-item t-20 col-grey">
              Bonussen en beloningen: Voor activiteiten in het spel.
            </li>
          </ul>
        </div>
        <div className="sugar-types__part">
          <div className="sugar-types___circle">Klassieke</div>
          <ul className="sugar-list">
            <li className="sugar-item t-20 col-grey">
              Cash Games: Vereist echte stortingen om in te zetten.
            </li>
            <li className="sugar-item t-20 col-grey">
              Mogelijkheid tot echte winsten: Spanning en echte prijzen.
            </li>
            <li className="sugar-item t-20 col-grey">
              Financieel risico: Mogelijke verliezen bij onsuccesvolle inzetten.
            </li>
            <li className="sugar-item t-20 col-grey">
              Stortingen en opnames: Transacties met echt geld vereist.
            </li>
          </ul>
        </div>
      </section>
      <section className="sugar-faq pad">
        <h2>Veelgestelde vragen:</h2>
        <ul className="sugar-faq__list">
          <li className="sugar-faq__item">
            <p className="sugar-faq__item-header">
              Kan ik bij een sociaal online casino spelen als ik een beperking
              heb om een klassiek casino te bezoeken?
            </p>
            <p className="t-20 sugar-faq__item-text">
              Sociale casino's maken geen gebruik van echt geld en daarom zal je
              niet beperkt worden om dergelijke sites te bezoeken. Maar wees
              voorzichtig, het spelmechanisme kan uw interesse wekken, wat kan
              leiden tot spelen voor echt geld.
            </p>
          </li>
          <li className="sugar-faq__item">
            <p className="sugar-faq__item-header">
              Kan ik mijn virtuele saldo inwisselen voor echt geld of prijzen?
            </p>
            <p className="t-20 sugar-faq__item-text">
              Nee, het virtuele saldo van een sociaal online casino heeft geen
              indexatie of conversie met echte valuta.
            </p>
          </li>
          <li className="sugar-faq__item">
            <p className="sugar-faq__item-header">
              Is een sociaal casino legaal in Nederland?
            </p>
            <p className="t-20 sugar-faq__item-text">
              Door het ontbreken van een financiële investering is spelen in
              Nederland niet in strijd met de lokale wetgeving en kun je legaal
              en veilig van je spelervaring genieten.
            </p>
          </li>
        </ul>
      </section>
      <section className="sugar-conclusion pad">
        <h2>Conclusie:</h2>
        <p className="t-20">
          Voor gokliefhebbers en nieuwkomers is een sociaal online casino een
          geweldige optie. Hier zie je directe sociale interacties tussen
          spelers. Het belangrijkste voordeel is nog steeds de mogelijkheid om
          legaal en veilig te gokken in Nederland. En de beschikbaarheid van
          goksites op alle soorten gadgets maakt het proces nog handiger.
        </p>
      </section>
    </main>
  );
};

export default MainCover;
