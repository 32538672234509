import React, { useState } from "react";
import "../Book/GamePage.css";
import bassCover from "../../images/big-bass-cover.webp";
import bassCoverMob from "../../images/big-bass-cover-mob.webp";
import closeBtn from "../../images/close-btn.svg";
import ellips from "../../images/elips-mob.svg";

const Bass = () => {
  const [isGameVisible, setIsGameVisible] = useState(false);

  return (
    <>
      <section className="sugar-game-page pad">
        <h1 className="sugar-main__header col-white">Big Bass Bonanza</h1>
        <p className="sugar-main__text col-white">
          Maak kennis met één van de populairste slots van Pragmatic Play.
          Ondanks de eenvoudige gameplay en de klassieke graphics, trekt het
          spel nieuwe gebruikers aan over de hele wereld. De eenvoud van het
          spelmechanisme en de mogelijkheid om je kapitaal te vermenigvuldigen
          trekt spelers aan. De slot heeft een RTP van 96,71%, wat een van de
          hoogste waarden op de markt is, en dankzij de bonusmechanica hebben
          spelers de mogelijkheid om hun inzet met 4000 keer te
          vermenigvuldigen.
        </p>
      </section>
      <section className="sugar-game-page__cover">
        <img
          src={bassCover}
          alt="Big Bass Bonanza cover"
          title="Big Bass Bonanza cover"
          className="sugar-game-page__img pc"
        />
        <img
          src={bassCoverMob}
          alt="Big Bass Bonanza cover"
          title="Big Bass Bonanza cover"
          className="sugar-game-page__img mob"
        />
        <div className="sugar-game-page__link">
          <p
            className="sugar-game-page__text"
            onClick={() => setIsGameVisible(true)}
          >
            Speel demo
          </p>
          <img src={ellips} alt="ellips" title="ellips" className="mob sugar-game-page__ellips" />
        </div>
      </section>
      {isGameVisible && (
        <div className="sugar-game-popup">
          <div className="sugar_popup__game">
            <img
              src={closeBtn}
              alt="close popup btn"
              title="close popup btn"
              className="sugar-popup__close"
              onClick={() => setIsGameVisible(false)}
            />
            <iframe
              title="Big Bass Bonanza"
              className="sugar-game__frame"
              src="https://demogamesfree.pragmaticplay.net/gs2c/openGame.do?lang=en&amp;cur=GBP&amp;gameSymbol=vs10bbbonanza&amp;websiteUrl=https://pragmaticplaygames.com%2Fen&amp;lobbyUrl=https://pragmaticplaygames.com%2Fen"
            ></iframe>
          </div>
        </div>
      )}
    </>
  );
};

export default Bass;
