import React, { useState } from "react";
import "./Casinos.css";
import about from "../../images/about.webp";
import book from "../../images/book-of-dead-img.webp";
import gold from "../../images/gold-cash-img.webp";
import phoenix from "../../images/phoenix-up-cash-img.webp";
import bass from "../../images/big-bass-img.webp";
import closeBtn from "../../images/close-btn.svg";
import { Link } from "react-router-dom";

const Casinos = () => {
  const [isGameOneVisible, setIsGameOneVisible] = useState(false);
  const [isGameTwoVisible, setIsGameTwoVisible] = useState(false);
  const [isGameThreeVisible, setIsGameThreeVisible] = useState(false);
  const [isGameFourVisible, setIsGameFourVisible] = useState(false);

  return (
    <section className="sugar-casinos pad">
      <div className="sugar-casinos__top">
        <div className="sugar-casinos__img-container">
          <img
            src={about}
            alt="man behind computer"
            title="man behind computer"
            className="sugar-casinos__img"
          />
        </div>
        <div className="sugar-casinos__text-container">
          <h2>Wat is een sociaal casino?</h2>
          <p className="t-20">
            Het is een gokruimte waar iedereen een gratis spelervaring krijgt
            aangeboden zonder financiële risico's. Een onderscheidend kenmerk
            naast het gebrek aan risico, is een uitgesproken sociale interactie
            tussen spelers om gemeenschappelijke doelen te bereiken
          </p>
        </div>
      </div>
      <ul className="sugar-casinos__grid">
        <li className="sugar-casinos__game">
          <img
            src={book}
            title="Book of Dead Slot"
            alt="Book of Dead Slot"
            className="sugar-casinos__game-cover"
          />
          <div className="sugar-casinos__overlay">
            <p className="sugar-casinos__name col-white">Book of Dead</p>
            <p
              className="btn sugar-casinos__play t-20"
              onClick={() => setIsGameOneVisible(true)}
            >
              Speel nu
            </p>
            <Link
              to="/book-of-dead-slot"
              className="btn sugar-casinos__more-info"
            >
              Lees de review
            </Link>
          </div>
        </li>
        <li className="sugar-casinos__game">
          <img
            src={gold}
            title="Gold Cash Free Spins"
            alt="Gold Cash Free Spins"
            className="sugar-casinos__game-cover"
          />
          <div className="sugar-casinos__overlay">
            <p className="sugar-casinos__name col-white">
              Gold Cash Free Spins
            </p>
            <p
              className="btn sugar-casinos__play t-20"
              onClick={() => setIsGameTwoVisible(true)}
            >
              Speel nu
            </p>
            <Link
              to="/gold-cash-free-spins"
              className="btn sugar-casinos__more-info"
            >
              Lees de review
            </Link>
          </div>
        </li>
        <li className="sugar-casinos__game">
          <img
            src={bass}
            title="Big Bass Bonanza"
            alt="Big Bass Bonanza"
            className="sugar-casinos__game-cover"
          />
          <div className="sugar-casinos__overlay">
            <p className="sugar-casinos__name col-white">Big Bass Bonanza</p>
            <p
              className="btn sugar-casinos__play t-20"
              onClick={() => setIsGameFourVisible(true)}
            >
              Speel nu
            </p>
            <Link
              to="/big-bass-bonanza"
              className="btn sugar-casinos__more-info"
            >
              Lees de review
            </Link>
          </div>
        </li>
        <li className="sugar-casinos__game">
          <img
            src={phoenix}
            title="Phoenix Up Cash"
            alt="Phoenix Up Cash"
            className="sugar-casinos__game-cover"
          />
          <div className="sugar-casinos__overlay">
            <p className="sugar-casinos__name col-white">Phoenix Up Cash</p>
            <p
              className="btn sugar-casinos__play t-20"
              onClick={() => setIsGameThreeVisible(true)}
            >
              Speel nu
            </p>
            <Link
              to="/phoenix-up-cash"
              className="btn sugar-casinos__more-info"
            >
              Lees de review
            </Link>
          </div>
        </li>
      </ul>

      {isGameOneVisible && (
        <div className="sugar-game-popup">
          <div className="sugar_popup__game">
            <img
              src={closeBtn}
              alt="close popup btn"
              title="close popup btn"
              className="sugar-popup__close"
              onClick={() => setIsGameOneVisible(false)}
            />
            <iframe
              title="book of dead"
              className="sugar-game__frame"
              src="https://asccw.playngonetwork.com/Casino/IframedView?pid=2&amp;gid=bookofdead&amp;gameId=360&amp;lang=en_US&amp;practice=1&amp;channel=desktop&amp;div=flashobject&amp;width=100%&amp;height=100%&amp;user=&amp;password=&amp;ctx=&amp;demo=2&amp;brand=&amp;lobby=&amp;rccurrentsessiontime=0&amp;rcintervaltime=0&amp;rcaccounthistoryurl=&amp;rccontinueurl=&amp;rcexiturl=&amp;rchistoryurlmode=&amp;autoplaylimits=0&amp;autoplayreset=0&amp;callback=flashCallback&amp;rcmga=&amp;resourcelevel=0&amp;hasjackpots=False&amp;country=&amp;pauseplay=&amp;playlimit=&amp;selftest=&amp;sessiontime=&amp;coreweburl=https://asccw.playngonetwork.com/&amp;showpoweredby=True"
            ></iframe>
          </div>
        </div>
      )}
      {isGameTwoVisible && (
        <div className="sugar-game-popup">
          <div className="sugar_popup__game">
            <img
              src={closeBtn}
              alt="close popup btn"
              title="close popup btn"
              className="sugar-popup__close"
              onClick={() => setIsGameTwoVisible(false)}
            />
            <iframe
              title="Gold Cash Free Spins"
              className="sugar-game__frame"
              src="https://inseinc.com/virgo/game/gold-cash-freespins-1-0-0-1-0-0?playerId=*&amp;device=mobile&amp;currency=GBP"
            ></iframe>
          </div>
        </div>
      )}
      {isGameThreeVisible && (
        <div className="sugar-game-popup">
          <div className="sugar_popup__game">
            <img
              src={closeBtn}
              alt="close popup btn"
              title="close popup btn"
              className="sugar-popup__close"
              onClick={() => setIsGameThreeVisible(false)}
            />
            <iframe
              title="Phoenix Up Cash"
              className="sugar-game__frame"
              src="https://d2drhksbtcqozo.cloudfront.net/casino/apex/layer/?channel=mobile&amp;gameid=phoenixupcash&amp;moneymode=fun&amp;jurisdiction=MT&amp;partnerid=1&amp;&amp;apex=1&amp;gameurl=https%3A%2F%2Fd2drhksbtcqozo.cloudfront.net%2Fcasino%2Flauncher.html"
            ></iframe>
          </div>
        </div>
      )}
      {isGameFourVisible && (
        <div className="sugar-game-popup">
          <div className="sugar_popup__game">
            <img
              src={closeBtn}
              alt="close popup btn"
              title="close popup btn"
              className="sugar-popup__close"
              onClick={() => setIsGameFourVisible(false)}
            />
            <iframe
              title="Big Bass Bonanza"
              className="sugar-game__frame"
              src="https://demogamesfree.pragmaticplay.net/gs2c/openGame.do?lang=en&amp;cur=GBP&amp;gameSymbol=vs10bbbonanza&amp;websiteUrl=https://pragmaticplaygames.com%2Fen&amp;lobbyUrl=https://pragmaticplaygames.com%2Fen"
            ></iframe>
          </div>
        </div>
      )}
    </section>
  );
};

export default Casinos;
