import React, { useState } from "react";
import "./GamePage.css";
import bookCover from "../../images/book-of-dead-cover.webp";
import bookCoverMob from "../../images/book-of-dead-cover-mob.webp";
import closeBtn from "../../images/close-btn.svg";
import ellips from "../../images/elips-mob.svg";

const Book = () => {

  const [isGameVisible, setIsGameVisible] = useState(false);

  return (
    <>
      <section className="sugar-game-page pad">
        <h1 className="sugar-main__header col-white">Book of Dead Slot</h1>
        <p className="sugar-main__text col-white">
          Duik diep in de oude tombes van de farao's om het Book of Dead te
          vinden, dat de weg opent naar fantastische schatten. Spelers krijgen
          betoverende graphics en interessante spelmechanismen voorgeschoteld,
          die de speelsessie verlengen en het maximale uit het spel halen. De
          slot is ontwikkeld door de provider Play'n GO, die eerlijkheid en
          transparantie garandeert.
        </p>
      </section>
      <section className="sugar-game-page__cover">
        <img
          src={bookCover}
          alt="Book of Dead Slot cover"
          title="Book of Dead Slot cover"
          className="sugar-game-page__img pc"
        />
        <img
          src={bookCoverMob}
          alt="Book of Dead Slot cover"
          title="Book of Dead Slot cover"
          className="sugar-game-page__img mob"
        />
        <div className="sugar-game-page__link">
          <p
            className="sugar-game-page__text"
            onClick={() => setIsGameVisible(true)}
          >
            Speel demo
          </p>
          <img src={ellips} alt="ellips" title="ellips" className="mob sugar-game-page__ellips" />
        </div>
      </section>
      {isGameVisible && (
        <div className="sugar-game-popup">
          <div className="sugar_popup__game">
            <img
              src={closeBtn}
              alt="close popup btn"
              title="close popup btn"
              className="sugar-popup__close"
              onClick={() => setIsGameVisible(false)}
            />
            <iframe
              title="book of dead"
              className="sugar-game__frame"
              src="https://asccw.playngonetwork.com/Casino/IframedView?pid=2&amp;gid=bookofdead&amp;gameId=360&amp;lang=en_US&amp;practice=1&amp;channel=desktop&amp;div=flashobject&amp;width=100%&amp;height=100%&amp;user=&amp;password=&amp;ctx=&amp;demo=2&amp;brand=&amp;lobby=&amp;rccurrentsessiontime=0&amp;rcintervaltime=0&amp;rcaccounthistoryurl=&amp;rccontinueurl=&amp;rcexiturl=&amp;rchistoryurlmode=&amp;autoplaylimits=0&amp;autoplayreset=0&amp;callback=flashCallback&amp;rcmga=&amp;resourcelevel=0&amp;hasjackpots=False&amp;country=&amp;pauseplay=&amp;playlimit=&amp;selftest=&amp;sessiontime=&amp;coreweburl=https://asccw.playngonetwork.com/&amp;showpoweredby=True"
            ></iframe>
          </div>
        </div>
      )}
    </>
  );
};

export default Book;
