import React, { useState } from "react";
import "../Book/GamePage.css";
import goldCover from "../../images/gold-cash-cover.webp";
import goldCoverMob from "../../images/gold-cash-cover-mob.webp";
import closeBtn from "../../images/close-btn.svg";
import ellips from "../../images/elips-mob.svg";

const Gold = () => {
  const [isGameVisible, setIsGameVisible] = useState(false);

  return (
    <>
      <section className="sugar-game-page pad">
        <h1 className="sugar-main__header col-white">Gold Cash Free Spins</h1>
        <p className="sugar-main__text col-white">
          Een van de populairste slots onder onze gebruikers, ontwikkeld door
          Inspired Gaming. Spelers worden uitgenodigd om te genieten van een
          spelruimte met 5 rollen die maar liefst 40 winlijnen geven. Met dit
          grote aantal lijnen kun je je kapitaal vermenigvuldigen. De fabrikant
          claimt een RTP van 95%, wat een uitstekend cijfer is.
        </p>
      </section>
      <section className="sugar-game-page__cover">
        <img
          src={goldCover}
          alt="Gold Cash Free Spins cover"
          title="Gold Cash Free Spins cover"
          className="sugar-game-page__img pc"
        />
        <img
          src={goldCoverMob}
          alt="Gold Cash Free Spins cover"
          title="Gold Cash Free Spins cover"
          className="sugar-game-page__img mob"
        />
        <div className="sugar-game-page__link">
          <p
            className="sugar-game-page__text"
            onClick={() => setIsGameVisible(true)}
          >
            Speel demo
          </p>
          <img src={ellips} alt="ellips" title="ellips" className="mob sugar-game-page__ellips" />
        </div>
      </section>
      {isGameVisible && (
        <div className="sugar-game-popup">
          <div className="sugar_popup__game">
            <img
              src={closeBtn}
              alt="close popup btn"
              title="close popup btn"
              className="sugar-popup__close"
              onClick={() => setIsGameVisible(false)}
            />
             <iframe
              title="Gold Cash Free Spins"
              className="sugar-game__frame"
              src="https://inseinc.com/virgo/game/gold-cash-freespins-1-0-0-1-0-0?playerId=*&amp;device=mobile&amp;currency=GBP"
            ></iframe>
          </div>
        </div>
      )}
    </>
  );
};

export default Gold;
