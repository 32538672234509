import { Link } from "react-router-dom";
import "./Header.css";
import burgerMenu from "../../images/burger-menu.svg";
import closeBtn from "../../images/close-btn.svg";
import { useState } from "react";

function Header() {
  const [isActive, setIsActive] = useState(false);

  function handleClick() {
    setIsActive(false);
  }

  return (
    <div className="sugar-header__container">
      <p className="sugar-header__logo">Sugarrush</p>
      <div className="sugar-header__link-container">
        <Link to="/" className="sugar-header__link">
          Hoofdpagina
        </Link>
        <Link to="/book-of-dead-slot" className="sugar-header__link">
          Book of Dead Slot
        </Link>
        <Link to="/gold-cash-free-spins" className="sugar-header__link">
          Gold Cash Free Spins
        </Link>
        <Link to="/phoenix-up-cash" className="sugar-header__link">
          Phoenix Up Cash
        </Link>
        <Link to="/big-bass-bonanza" className="sugar-header__link">
          Big Bass Bonanza
        </Link>
      </div>

      <div className="burger-menu">
        <div id="burger-menu">
          <img
            src={burgerMenu}
            alt="burger menu icon"
            className="sugar-burger__icon"
            onClick={() => setIsActive(true)}
          />
        </div>
        <nav
          className={`burger-menu__nav ${isActive ? "active" : ""}`}
          id="nav"
        >
          <img
            src={closeBtn}
            className="burger__close-btn"
            id="close-icon"
            alt="close menu icon"
            onClick={handleClick}
          />
          <div className="burger-menu__nav-container">
            <Link to="/" className="sugar-header__link" onClick={handleClick}>
              Hoofdpagina
            </Link>
            <Link to="/book-of-dead-slot" className="sugar-header__link" onClick={handleClick}>
              Book of Dead Slot
            </Link>
            <Link to="/gold-cash-free-spins" className="sugar-header__link" onClick={handleClick}>
              Gold Cash Free Spins
            </Link>
            <Link to="/phoenix-up-cash" className="sugar-header__link" onClick={handleClick}>
              Phoenix Up Cash
            </Link>
            <Link to="/big-bass-bonanza" className="sugar-header__link" onClick={handleClick}>
              Big Bass Bonanza
            </Link>
          </div>
        </nav>
      </div>
    </div>
  );
}

export default Header;
